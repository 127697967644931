
.dashboard-user{
    .nav-tabs{
        border-bottom:1px solid #ccc !important;
        margin-bottom:0;
        .nav-link{
            background: #ffffff;
            color:#000;
            padding: 0.5rem 1.2rem;
            border-top:1px solid #ccc;
            border-right:1px solid #ccc;
            border-left:1px solid #ccc;
            margin-right:0;
            display:inline-block;
            margin-bottom:0;
            &:hover{
                border-top:1px solid #ccc;
                border-right:1px solid #ccc;
                border-left:1px solid #ccc;
            }
        }
        .active{
            background: #00549e !important;
            color:#fff !important;
             padding-bottom:0.6rem;
            &:hover{
                border:none;
            }
        }

    }
    .btn-search{
        padding: 0.25rem 0.75rem;
        margin-left:0.4em;
        font-size:0.8rem;
    }
    .btn-dark-orange{
        color:#ffffff;
        background:darkorange;
    }
    .btn{
        min-width:42px;
    }
    .pagination .page-item.active .page-link{
        color:#fff !important;
    }
    .pagination .page-link{
        color:#212529 !important;
    }
    .data-table-pagination{
        grid-area:none;
        grid-column: 3/span 2 !important;
    }
}
