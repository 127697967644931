.breadcrumbs {
    font-family: $font-family-pip-main;
    font-weight: 500;
    color: grey;
    font-size: .9rem;
}

#filters {
    background: #ccc;
    color: #333;
    padding: 2rem;
    h3 {
        font-family: $font-family-pip-main;
        font-weight: 700;
    }
    .filter-div {
        border-bottom: 1px solid #999;
        padding: 1em;
    }
}

.product {
    @media screen and (min-width: 909px) {
        max-width: 275px;
    }
    img {
        max-width: 75%;
    }
}