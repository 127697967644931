
main {
    min-height: 77vh;
    background: white;
    font-family: $font-family-pip-main;


    .industry {
        background-position: center;
        background-repeat: no-repeat;
        min-width: 25%;
        height: 60vh;
        position: relative;
        transition: all .4s ease-out;
        .industry-text {
            background: transparent;
            color: white;
            padding: 2em;
            z-index: 2;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 991px){
                background: rgba(0,0,0,.6);
            }
            h3 {
                font-family: "Oswald", Arial, Helvetica, sans-serif;
                font-size: 3.5rem;
                font-weight: 800;
                text-transform: uppercase;
                text-align: center;
                line-height: 1;
            }
            a.btn {
                max-width: 50%;
            }

        }
        &:hover {
            cursor: pointer;
            .industry-text {
                background: rgba(0,0,0,.6);
            }
        }

        @media (max-width: 1168px){
            min-width: 50%;
            height: 40vh;
        }
    }

    #ear-protection {
        background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6) ),url('/images/category-images-hearing.jpg');
        background-position: 32%;
        @media screen and (max-width: 974px) {
            background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ),url('/images/category-images-hearing.jpg');
        }
    }
    #head-protection {
        background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6) ),url('/images/category-images-wheel-ratchet.jpg');
        background-position: 66%;
        @media screen and (max-width: 974px) {
            background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ),url('/images/category-images-wheel-ratchet.jpg');
        }
    }
    #eye-protection {
        background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6) ),url('/images/headers/Eyewear-Divider.jpg');
        background-position: 66%;
        @media screen and (max-width: 974px) {
            background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ),url('/images/headers/Eyewear-Divider.jpg');
        }
    }

    #industries {
        min-height: 20Vh;
        flex-wrap: wrap;


    }
    #brands {
        display: flex;
        flex-wrap: wrap;
        background: $primary;
        color: white;
        padding: 2rem;
        .brands-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 2rem 3rem;
            h4 {
                text-transform: uppercase;
                font-size: 1rem;
            }
        }
        .brands-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            flex-grow: 3;
            .brand {
                margin: 1em .5em;
                max-width: 150px;
                img {
                    max-width: 100%;
                }
            }
        }

    }
}

.lines {
    background-image: url('/images/diagonal-lines.png');
    background-repeat: repeat-y;
    height: 90%;
    overflow-y: hidden;
}

#hero-slider {
    min-height: 40vh;
    background: $secondary;
   .img-fluid {
       min-height: 250px;
       object-fit: cover;
   }
   .carousel-control-next-icon,
   .carousel-control-prev-icon {
        width: 30px;
        height: 30px;
   }
   .carousel-control-next-icon {
       transform: translateX(48px);
   }
   .carousel-control-prev-icon {
    transform: translateX(-48px);
}
}

#scrollToTop {
    position: fixed;
    display: none;
    bottom: 2rem;
    right: 2rem;
    z-index: 1000;
    border-radius: 50%;
    background: $primary;
    width: 3.4rem;
    height: 3.4rem;
    padding: 0.3rem;
    font-size: 2.2rem;
    align-items: center;
    justify-content: center;
    &:hover {
        cursor: pointer;
    }
}


