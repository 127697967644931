#main-pagination {
    .pagination {

        .page-item.active {
            .page-link {
                background-color: #333;
                border-color: #111;
                font-family: "Acumin Pro", Helvetica, Arial, sans-serif;
                font-size: 12px !important;

            }

        }
        .page-link {
            color: $primary;
            border: none !important;
            &:hover {
                background: transparent;
            }
        }
        li.page-item.first,
        li.page-item.last {
            display: none !important;
        }
        li.next {
            .page-link {
                margin-left: 1em;
                float: unset !important;
                position: absolute;
                top: 4px;
                right: 0;
            }
        }
        li.previous {
            .page-link {
                margin-right: .5em;
                float: unset !important;
                padding: 6px 12px !important;
                position: absolute;
                top: 4px;
                right: 47px;
                margin-right: 13px !important;
            }
        }
        &.double-digit {
            li.previous {
                .page-link {
                    padding: 6px 18px !important;
                }
            }
        }
        .page-item.page-number {
            display: none !important;
        }
        li.page-item.page-number.active {
            display: inline-block !important;
            transform: translate(-64px, 4px);
            a.page-link {
                background: white !important;
                color: black;
                padding: unset !important;
                float: unset !important;
                position: absolute;
                top: -12px;
                right: -9px;
            }

        }

        // li.page-item.next {
        //     padding-left: 4px;
        //     margin-top: -1px;
        // }
    }

}

#main-pagination .pagination.double-digit li.page-item.page-number.active a.page-link {
    right: -2px;
}




