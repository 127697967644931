label.label {
    padding: .2rem .35rem;
    border-radius: 5px 5px 0 0;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 0.8rem;
}

label {
    margin-bottom: 0;
}

.input-group-prepend {
    background: #626262;
    padding: 0 .2rem;
}