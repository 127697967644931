
.my-tools{
   font-family: 'PT Sans Narrow', sans-serif;
   h3{
       font-size:2em;
       margin:0.5em 0;
       font-family: 'PT Sans Narrow', sans-serif;
       font-weight:700;

   }
   ul{
       list-style: none;
       display:flex;
       flex-wrap: wrap;
        li{
            width:150px;
            margin:0 1.5em 1em 0;
            figure{text-align: center; margin-bottom:1em;}
            select{
                width:100%;
            }
        }
   }
}
.sidebar{
    h1{
        color:#333;
        font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
        font-weight:400;
        padding-bottom:1rem;
        border-bottom: 3px solid #efefef;
    }
    .lines {
        background: url("/images/diagonal-lines.png") top center no-repeat;

    }

}
.dashboard-main{
    padding:0 4rem;
    @media only screen and (max-width: 990px){
        padding:0 2rem;
    }
    @media only screen and (max-width: 500px){
        padding:0 ;
    }

}


.dashboard-user{
    .nav-tabs, .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus, .nav-tabs .nav-link{
        border:none;
    }
    .tab-content{
        padding: 2em 0;
    }
    .nav-link{
        padding:0;
        margin-right:2em;
    }
    @media only screen and (max-width: 1200px){
        table{
            tr{
                th:nth-child(6),th:nth-child(3), th:nth-child(4), th:nth-child(5){
                    display:none;
                }
                td:nth-child(6),td:nth-child(3), td:nth-child(4), td:nth-child(5){
                    display:none;
                }
            }

        }

    }
}
