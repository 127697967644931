.mm-menu {
    --mm-color-background: #446a8b;
    --mm-color-text: #ccc;
}

.mm-navbars_top {
    --mm-color-background: #333;
}

.mm-panel {
    --mm-color-background: #525252;
    --mm-color-text: white;
    --mm-color-button: white;
}

.mm-menu,
.mm-navbar__title > span {
    font-family: "Acumin Pro Condensed", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
}