footer {
    padding: 4em;
    background: #111;
    color: whitesmoke;
    font-family: $font-family-pip-main;
    .footer-links-container {

        ul {
            padding: 0;
            list-style: none;
            li {
                a {
                    color: white;
                    text-decoration: none;
                    font-size: .9rem;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        p.heading {
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 0;
            letter-spacing: 1px;
        }
        p.number,
        p.address {
            font-size: .9em;
        }
    }
    .copyright {
        text-align: center;
    }
}