.login-container{
    width:90%;
    margin:4em auto 0 auto;

    i{
        color:#00549e;
    }
    h1{
        border-bottom: 3px solid #efefef;
        font-size:3em;
        margin: 0 0 1.6rem 0;
        color:#111;
    }
    h3 {
        color: #00549e;
        font-weight:600;
        border-bottom:1px solid #00549e;
        margin-bottom:1em;
        span{
            font-weight: 400;
            font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
        }
    }
    label{
        text-transform: uppercase;
        font-weight:600;
        margin-bottom:0;
    }
}
