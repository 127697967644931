.literature-container {
    display: flex;
    justify-content: flex-start;
    padding: 0 2rem 4rem;
    flex-wrap: wrap;
    @media screen and (max-width: 1190px){
        justify-content: space-around;
    }
    .literature {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 225px;
        img {
            max-width: 100%;
        }
        a.button {
            font-size: .8rem;
            font-weight: 700;
            border: none;
            width: 70%;
            padding: .3rem;
            margin: .5rem auto;
            text-align: center;
            &:hover {
                text-decoration: none;
            }
        }
        a.btn-red {
            background: $red;
            color: whitesmoke;
        }
    }
}